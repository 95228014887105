import Swiper from "swiper/bundle";

window.swipers = {};

export function scrollClasses($this, $swiperContainer) {
    if ($swiperContainer !== null) {
        if ($this.isBeginning) {
            $swiperContainer.classList.add("scroll-start");
        } else {
            $swiperContainer.classList.remove("scroll-start");
        }

        if ($this.isEnd) {
            $swiperContainer.classList.add("scroll-end");
        } else {
            $swiperContainer.classList.remove("scroll-end");
        }
    }
}

export class swiperScroll {
    constructor(id, options) {
        this._swiperId = id;
        this._initialOptions = options;

        this.initialize(this._initialOptions);
        this.scroll(this._swiperId);
    }

    initialize(options) {
        this._swiper = new Swiper(`#${this._swiperId}`, options);
        const $swiperContainer = document.getElementById(this._swiperId);

        scrollClasses(this._swiper, $swiperContainer);
    }

    scroll(id) {
        const $swiperContainer = document.getElementById(id);

        this._swiper.on("reachBeginning sliderMove scroll", function () {
            scrollClasses(this, $swiperContainer);
        });
    }

    update() {
        this._swiper.update();
    }

    updateSlidesClasses() {
        this._swiper.updateSlidesClasses();
    }

    setTranslate(translate) {
        this._swiper.setTranslate(translate);
    }

    updateTranslate(translate) {
        this.update();
        this.setTranslate(translate);
    }

    getCurrentTranslate() {
        return this._swiper.translate;
    }
}

export class swiperSlider {
    constructor(id, options) {
        this._swiperId = id;
        this._initialOptions = options;
        this.initialize(options);
        this.scroll(id);
    }

    initialize(options) {
        this._swiper = new Swiper(`#${this._swiperId}`, options);
        var $swiperContainer = document.getElementById(this._swiperId);

        scrollClasses(this._swiper, $swiperContainer);
    }

    scroll(id) {
        this._swiper.on("sliderMove", function () {
            var $swiperContainer = document.getElementById(id);

            scrollClasses(this, $swiperContainer);
        });
    }
}

window.initializeSwiperSlider = function (id, options = {}) {
    window.swipers[id] = new swiperSlider(id, {
        direction: "horizontal",
        slidesPerView: options.perView || 1,
        spaceBetween: options.gap || 24,
        watchSlidesProgress: true,
        centerInsufficientSlides: options.centerSlides || false,
        breakpoints: options.breakpoints || [],
        navigation: {
            nextEl: "#" + id + "> .swiper-button-next",
            prevEl: "#" + id + "> .swiper-button-prev",
        },
        pagination: {
            el: "#" + id + "> .swiper-pagination",
            clickable: true,
        },
        initialSlide: options.initialSlide || 0,
        centeredSlides: options.focusCenter || false,
        loop: options.loop || false,
        autoplay: {
            enabled: options.autoplay || false,
            delay: options.autoplayDelay || 10000,
        },
    });
};

window.initializeSwiperScrollHorizontal = function (id, options = {}) {
    window.swipers[id] = new swiperScroll(id, {
        direction: "horizontal",
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesProgress: true,
        centerInsufficientSlides: options.centerSlides || false,
        scrollbar: {
            el: "#" + id + "> .swiper-scrollbar",
            draggable: true,
        },
        navigation: {
            nextEl: "#" + id + "> .swiper-button-next",
            prevEl: "#" + id + "> .swiper-button-prev",
        },
        pagination: {
            el: "#" + id + "> .swiper-pagination",
            clickable: true,
        },
        mousewheel: {
            forceToAxis: true,
        },
        on: {
            init: function () {
                let swiper = this;

                setTimeout(function () {
                    swiper.slideTo(options.initialSlide - 1, 800, false);
                }, 500);
            },
        },
    });
};

window.initializeSwiperScrollVertical = function (id) {
    window.swipers[id] = new swiperScroll(id, {
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesProgress: true,
        scrollbar: {
            el: "#" + id + "> .swiper-scrollbar",
            draggable: true,
        },
        mousewheel: {
            forceToAxis: true,
        },
    });
};
